<template>
  <div
    class="text-fields-block__row text-fields-block__row_account"
    :class="{ 'text-fields-block__row_account-change': for_table }"
  >
    <div
      class="text-fields-block__element text-fields-block__element_accounts"
      :class="{ 'text-fields-block__element_account-change': for_table }"
    >
      <span :class="{ invalid_field: this.invalid.first_name }">{{
        $t("accounts.first_name")
      }}</span>
      <custom-text-field
        ref="first_name"
        :rules="[rules.require, rules.max_length_50]"
        class="text-fields-block__field"
        v-model="new_account.first_name"
        :placeholder="$t('accounts.text_field_hint')"
        @change="refresh"
      ></custom-text-field>
    </div>
    <div
      class="text-fields-block__element text-fields-block__element_accounts"
      :class="{ 'text-fields-block__element_account-change': for_table }"
    >
      <span :class="{ invalid_field: this.invalid.last_name }">{{
        $t("accounts.last_name")
      }}</span>
      <custom-text-field
        ref="last_name"
        :rules="[rules.require, rules.max_length_50]"
        class="text-fields-block__field"
        v-model="new_account.last_name"
        :placeholder="$t('accounts.text_field_hint')"
        @change="refresh"
      ></custom-text-field>
    </div>
    <div
      class="text-fields-block__element text-fields-block__element_accounts"
      :class="{ 'text-fields-block__element_account-change': for_table }"
    >
      <span :class="{ invalid_field: this.invalid.position }">{{
        $t("accounts.position")
      }}</span>
      <custom-text-field
        ref="position"
        :rules="[rules.require, rules.max_length_50]"
        class="text-fields-block__field"
        v-model="new_account.position"
        :placeholder="$t('accounts.text_field_hint')"
        @change="refresh"
      ></custom-text-field>
    </div>
    <div
      class="text-fields-block__element text-fields-block__element_accounts"
      :class="{ 'text-fields-block__element_account-change': for_table }"
    >
      <span :class="{ invalid_field: this.invalid.mail }">{{
        $t("accounts.mail")
      }}</span>
      <custom-text-field
        ref="mail"
        :rules="mail_rule"
        class="text-fields-block__field"
        v-model="new_account.mail"
        :placeholder="$t('accounts.text_field_hint')"
        @change="refresh"
      ></custom-text-field>
    </div>
    <div
      v-if="!for_table"
      class="text-fields-block__element text-fields-block__element_accounts"
    >
      <span :class="{ invalid_field: this.invalid.password }">{{
        $t("accounts.password")
      }}</span>
      <custom-text-field
        ref="password"
        :rules="[rules.require, rules.password]"
        class="text-fields-block__field"
        v-model="new_account.password"
        :placeholder="$t('accounts.text_field_hint')"
        @change="refresh"
        ><simple-button
          class="text-fields-block__generate-btn"
          :title="$t('accounts.generate')"
          @click="generate"
        ></simple-button
      ></custom-text-field>
    </div>
    <div
      v-if="!for_table"
      class="text-fields-block__element text-fields-block__element_accounts"
    ></div>
  </div>
</template>

<script>
import CustomTextField from "@/components/recurringComponents/customTextField";
import SimpleButton from "@/components/recurringComponents/simpleButton";
import rules from "@/rules";

export default {
  name: "accountsTextFields",
  props: {
    for_table: Boolean,
    edit: {
      type: Boolean,
      default: false,
    },
  },
  components: { SimpleButton, CustomTextField },
  data() {
    return {
      rules: rules,
      invalid: {
        first_name: false,
        last_name: false,
        position: false,
        mail: false,
        password: false,
      },
    };
  },
  computed: {
    new_account: {
      get() {
        if (this.edit) {
          return this.$store.getters.EDIT_ACCOUNT;
        }
        return this.$store.getters.NEW_ACCOUNT;
      },
      set(value) {
        if (this.edit) {
          this.$store.dispatch("update_edit_account", value);
        } else {
          this.$store.dispatch("update_new_account", value);
        }
      },
    },
    mail_rule() {
      let reg = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
      return [
        (v) => !!v || "required",
        (v) => reg.test(v) || "no_mail",
        (v) => this.email_exist(v) || "mail_exist",
      ];
    },
  },
  methods: {
    email_exist(value) {
      if (this.for_table && this.new_account.mail === value) {
        return true;
      }
      let dubl = this.$store.getters.ACCOUNTS.find((account) => {
        return account.login === value;
      });
      if (dubl !== undefined) {
        return false;
      }
      return true;
    },
    refresh() {
      this.$emit("change", { value: this.new_account });
    },
    generate() {
      let symb = `!@#$%^&*()_+~\`|}{[]:;?><,./-=abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789`;
      symb = Array.from(symb);
      let pass = "";
      for (let i = 0; i <= Math.round(Math.random() * 4) + 10; i++) {
        let s = symb[Math.round(Math.random() * (symb.length - 1))];
        pass = pass + s;
      }
      if (rules.password(pass) === true) {
        this.new_account.password = pass;
        this.refresh();
      } else this.generate();
    },
    validate() {
      let error = false;
      let error_type = "";
      for (let key in this.$refs) {
        let valid = this.$refs[key].validate();
        this._data["invalid"][key] = !valid.valid;
        if (!valid.valid) {
          error_type = valid.error;
          error = true;
        }
      }
      return { valid: !error, error: error_type };
    },
  },
};
</script>

<style scoped></style>
