<template>
  <div class="accounts-check-block">
    <check-block
      class="accounts-check-block__check-element"
      v-model="new_account.add_hubs"
      :title="$t('accounts.add_hubs')"
      :on_text="$t('accounts.yes')"
      :off_text="$t('accounts.no')"
      :small="true"
    ></check-block>
    <check-block
      class="accounts-check-block__check-element"
      v-model="new_account.add_users"
      :title="$t('accounts.add_users')"
      :on_text="$t('accounts.yes')"
      :off_text="$t('accounts.no')"
      :small="true"
    ></check-block>
    <check-block
      class="accounts-check-block__check-element"
      v-model="new_account.hub_settings"
      :title="$t('accounts.change_settings')"
      :on_text="$t('accounts.yes')"
      :off_text="$t('accounts.no')"
      :small="true"
    ></check-block>
    <check-block
      class="accounts-check-block__check-element"
      v-model="new_account.application_permission"
      :title="$t('accounts.application_permission')"
      :on_text="$t('accounts.yes')"
      :off_text="$t('accounts.no')"
      :small="true"
    ></check-block>
  </div>
</template>

<script>
import CheckBlock from "@/components/recurringComponents/checkBlock";
export default {
  name: "accountsCheckBlock",
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CheckBlock,
  },
  data() {
    return {};
  },
  computed: {
    new_account: {
      get() {
        if (this.edit) {
          return this.$store.getters.EDIT_ACCOUNT;
        }
        return this.$store.getters.NEW_ACCOUNT;
      },
      set(value) {
        if (this.edit) {
          this.$store.dispatch("update_edit_account", value);
        } else {
          this.$store.dispatch("update_new_account", value);
        }
      },
    },
  },
  methods: {
    // refresh() {
    //   this.$emit("input", this.new_account);
    //   this.$emit("change");
    // },
  },
};
</script>

<style scoped></style>
