<template>
  <div class="check-block">
    <div
      class="check-block__title"
      :class="{ 'check-block__title_small': small }"
    >
      <div class="check-block__title-text">
        {{ title
        }}<popup-hint class="hint-icon" v-if="hint">{{ hint_text }}</popup-hint>
      </div>
      <span class="check-block__info" :class="{ error: error }">{{
        error_text
      }}</span>
    </div>
    <div
      :class="{
        'check-block__row_small': small,
        'check-block__row': row,
        'check-block__column': column,
      }"
    >
      <label
        ><input
          class="check-block__input"
          v-model="val"
          :value="value_on"
          type="radio"
          :name="id"
        /><span class="check-block__text">{{ on_text }}</span></label
      >
      <label
        ><input
          class="check-block__input"
          v-model="val"
          :value="value_off"
          type="radio"
          :name="id"
        /><span class="check-block__text">{{ off_text }}</span></label
      >
    </div>
  </div>
</template>

<script>
import PopupHint from "@/components/recurringComponents/popupHint";
export default {
  name: "checkBlockTextValue",
  props: {
    row: Boolean,
    column: Boolean,
    value: String,
    value_on: String,
    value_off: String,
    id: String,
    title: String,
    on_text: String,
    off_text: String,
    hint: Boolean,
    hint_text: String,
    error: Boolean,
    error_text: String,
    small: Boolean,
  },
  components: { PopupHint },
  data() {
    return {};
  },
  computed: {
    on: {
      get() {
        return this.value;
      },
      set(value) {
        console.log(value);
      },
    },
    val: {
      get() {
        return this.on === this.value_on ? this.value_on : this.value_off;
      },
      set(val) {
        this.$emit("input", val);
        this.$emit("change");
      },
    },
  },
};
</script>

<style scoped>
.check-block__row {
  display: flex;
  gap: 20px;
}

.check-block__column {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>
