<template>
  <div class="change-block">
    <accounts-text-fields
      ref="fields"
      class=""
      :for_table="true"
      :edit="true"
    ></accounts-text-fields>
    <accounts-check-block
      :edit="true"
      class="accounts__check-block"
    ></accounts-check-block>
    <accounts-access-block-edit></accounts-access-block-edit>

    <div
      v-if="info.loader"
      class="change-block__btn-block change-block__btn-block_accounts"
    >
      <div class="change-block__info-block">
        <loader></loader>
        <span
          class="text-fields-block__info-text text_color_green"
          :class="{ text_color_red: info.error }"
          >{{ info_text }}</span
        >
      </div>
    </div>
    <div
      v-else
      class="change-block__btn-block change-block__btn-block_accounts"
    >
      <div>
        <custom-btn
          class="button button_theme_blue"
          :disabled="disabled_button"
          @click="edit_account"
          >{{ $t("accounts.change_block.save") }}</custom-btn
        >
        <simple-button
          :title="$t('accounts.change_block.cancel')"
          @click="$emit('cancel')"
        ></simple-button>
        <span
          class="text_color_green"
          :class="{ text_color_red: info.error }"
          >{{ info_text }}</span
        >
      </div>
      <custom-btn
        class="change-block__delete-button button button_theme_delete"
        @click="$emit('delete')"
      >
        <span>{{ $t("accounts.change_block.delete") }}</span>
        <div class="change-block__delete-icon"></div>
      </custom-btn>
    </div>
  </div>
</template>

<script>
import CustomBtn from "@/components/recurringComponents/customBtn";
import SimpleButton from "@/components/recurringComponents/simpleButton";
import AccountsTextFields from "@/components/components/accounts/accountsTextFields";
import AccountsCheckBlock from "@/components/components/accounts/accountsCheckBlock";
import AccountsAccessBlockEdit from "@/components/components/accounts/accountsAccessBlockEdit";
import Loader from "@/components/recurringComponents/loader";

export default {
  name: "accountsChangeBlock",
  props: ["value"],
  components: {
    Loader,
    AccountsCheckBlock,
    AccountsTextFields,
    AccountsAccessBlockEdit,
    SimpleButton,
    CustomBtn,
  },
  created() {
    const account_update_values = {
      id: this.value.id,
      first_name: this.value.first_name,
      last_name: this.value.last_name,
      add_hubs: this.value.add_hubs,
      add_users: this.value.add_users,
      hub_settings: this.value.hub_settings,
      application_permission: this.value.application_permission,
      mail: this.value.mail,
      position: this.value.position,
    };
    this.$store.dispatch("update_edit_account", account_update_values);
  },
  data() {
    return {
      info: {
        error: false,
        type: "",
        loader: false,
      },
    };
  },
  computed: {
    disabled_button() {
      const access = this.$store.getters.EDIT_ACCOUNT_ACCESS;
      return (
        access.mode !== "all" &&
        (access.selected.length === 0 ||
          access.selected.some((x) => x.pos.length === 0))
      );
    },
    info_text() {
      switch (this.info.type) {
        case "required":
          return this.$i18n.t("errors.required");
        case "no_format":
          return this.$i18n.t("errors.format");
        case "no_password":
          return this.$i18n.t("errors.value");
        case "progress":
          return this.$i18n.t("team.loader.progress_edit");
        case "failed":
          return this.$i18n.t("team.loader.failed_edit");
        case "success":
          return this.$i18n.t("team.loader.success_edit");
        default:
          return "";
      }
    },
  },
  methods: {
    validate() {
      this.info.error = false;
      this.info.type = "";
      for (let key in this.$refs) {
        let valid = this.$refs[key].validate();
        if (!valid.valid) {
          this.info.error = true;
          this.info.type = valid.error;
        }
      }
      return !this.info.error;
    },
    edit_account() {
      if (this.validate()) {
        this.info.loader = true;
        this.info.type = "progress";
        let access_data = { ...this.$store.getters.EDIT_ACCOUNT_ACCESS };
        delete access_data.available;
        this.$store.getters.EDIT_ACCOUNT.access = {
          ...access_data,
        };
        this.$store
          .dispatch("update_account")
          .then(() => {
            this.info.type = "success";
          })
          .catch(() => {
            this.info.type = "failed";
            this.info.error = true;
          })
          .finally(() => {
            this.info.loader = false;
          });
      }
    },
  },
};
</script>

<style scoped></style>
