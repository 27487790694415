<template>
  <div class="body">
    <div class="text-fields-block block-wide">
      <form @submit.prevent="add_account">
        <accounts-text-fields
          ref="add_account"
          :for_table="false"
        ></accounts-text-fields>

        <accounts-check-block></accounts-check-block>
        <accounts-access-block ref="accessBlockRef"></accounts-access-block>

        <div
          class="text-fields-block__btn-row text-fields-block__btn-row_accounts"
        >
          <loader v-if="info.loader"></loader>
          <custom-btn
            v-else
            class="button button_theme_blue"
            type="submit"
            :disabled="disabled_button"
            >{{ $t("accounts.create") }}</custom-btn
          >
          <span
            v-if="empty_cities"
            class="text-fields-block__info-text text_color_red"
            >{{ $t("accounts.empty_cities") }}
            <span
              @click="goto_settings()"
              style="text-decoration: underline; cursor: pointer"
              >{{ $t("accounts.settings") }}</span
            ></span
          >
          <span
            v-if="info.type"
            class="text-fields-block__info-text text_color_green"
            :class="{ text_color_red: info.error }"
            >{{ info_text }}</span
          >
        </div>
      </form>
    </div>
    <div class="accounts__table block-wide">
      <accounts-table
        :header_items="headers"
        :items="accounts"
        @change_sorted="change_sorted"
      ></accounts-table>
    </div>
  </div>
</template>

<script>
import CustomBtn from "@/components/recurringComponents/customBtn";
import AccountsTextFields from "@/components/components/accounts/accountsTextFields";
import AccountsCheckBlock from "@/components/components/accounts/accountsCheckBlock";
import AccountsTable from "@/components/components/accounts/accountsTable";
import AccountsAccessBlock from "@/components/components/accounts/accountsAccessBlock";
import Loader from "@/components/recurringComponents/loader";
import moment from "moment-timezone";

export default {
  name: "accounts",
  components: {
    Loader,
    AccountsTable,
    AccountsCheckBlock,
    AccountsTextFields,
    AccountsAccessBlock,
    CustomBtn,
  },
  data() {
    return {
      empty_cities: false,
      info: {
        error: false,
        type: "",
        loader: false,
      },
      headers: [
        {
          name: "account_id",
          title: this.$i18n.t("accounts.headers.account_id"),
          sorted: 1,
        },
        {
          name: "first_name",
          title: this.$i18n.t("accounts.headers.first_name"),
          sorted: 0,
        },
        {
          name: "last_name",
          title: this.$i18n.t("accounts.headers.last_name"),
          sorted: 0,
        },
        {
          name: "position",
          title: this.$i18n.t("accounts.headers.position"),
          sorted: 0,
        },
        {
          name: "mail",
          title: this.$i18n.t("accounts.headers.mail"),
          sorted: 0,
        },
        {
          name: "date",
          title: this.$i18n.t("accounts.headers.date"),
          sorted: 0,
        },
      ],
    };
  },
  created() {
    this.$store.dispatch("load_accounts");
    this.check_empty_cities();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$socket.readyState === 1) {
        vm.$socket.sendObj({
          route: to.name,
          token: localStorage.getItem("access_token"),
        });
      }
    });
  },
  watch: {
    "$i18n.locale"() {
      for (let item of this.headers) {
        item.title = this.$t(`accounts.headers.${item.name}`);
      }
    },
  },
  computed: {
    disabled_button() {
      const { access } = this.$store.getters.NEW_ACCOUNT;
      return (
        access.mode !== "all" &&
        (access.selected.length === 0 ||
          !access.selected.some((x) => x.pos.length > 0))
      );
    },
    accounts() {
      return this.$store.getters.ACCOUNTS.map((account) => {
        let add_hubs = account.role.permissions.includes("add_hubs");
        let add_users = account.role.permissions.includes("add_users");
        let hub_settings = account.role.permissions.includes("hub_settings");
        let application_permission = account.role.permissions.includes(
          "application_permission"
        );

        return {
          id: account.id,
          account_id: account.account_id,
          first_name: account.name,
          last_name: account.surname,
          position: account.company_position,
          mail: account.login,
          date: this.convert_to_local_tz(account.created_at),
          add_hubs: add_hubs,
          add_users: add_users,
          hub_settings: hub_settings,
          application_permission: application_permission,
        };
      });
    },
    info_text() {
      switch (this.info.type) {
        case "required":
          return this.$i18n.t("errors.required");
        case "no_format":
          return this.$i18n.t("errors.format");
        case "no_password":
          return this.$i18n.t("errors.value");
        case "no_mail":
          return this.$i18n.t("customers.errors.no_mail");
        case "mail_exist":
          return this.$i18n.t("customers.loader.mail_exist");
        case "progress":
          return this.$i18n.t("team.loader.progress");
        case "failed":
          return this.$i18n.t("team.loader.failed");
        case "success":
          return this.$i18n.t("team.loader.success");
        default:
          return "";
      }
    },
  },
  methods: {
    goto_settings() {
      this.$router.push("/settings");
    },
    check_empty_cities() {
      this.empty_cities = false;
      setTimeout(() => {
        if (this.$store.getters.CITIES.length === 0) {
          this.empty_cities = true;
        }
      }, 2000);
    },
    change_sorted(item) {
      let change_item = this.headers.find((it) => it.name === item);
      let sort = change_item.sorted;

      this.headers.forEach((item) => (item.sorted = 0));

      if (sort !== 1) change_item.sorted = 1;
      else change_item.sorted = -1;

      if (change_item.sorted !== 0) {
        this.accounts.sort((a, b) => {
          if (a[item] > b[item]) return change_item.sorted;
          if (a[item] === b[item]) return 0;
          if (a[item] < b[item]) return -change_item.sorted;
        });
      }
    },
    convert_to_local_tz(date) {
      let tz = moment.tz.guess();
      let tzOffset = moment.tz(tz).format("Z");
      return moment
        .utc(date, "DD.MM.YYYY HH:mm:ss", tz)
        .utcOffset(tzOffset)
        .format("DD.MM.YYYY HH:mm:ss");
    },
    validate() {
      let valid = this.$refs.add_account.validate();
      this.info.error = !valid.valid;
      this.info.type = valid.error;
      return !this.info.error;
    },
    add_account() {
      if (this.validate()) {
        this.info.loader = true;
        this.info.type = "progress";
        this.$store
          .dispatch("add_account")
          .then(() => {
            this.info.type = "success";
            this.$store.dispatch("update_new_account", {
              first_name: "",
              last_name: "",
              position: "",
              mail: "",
              password: "",
              add_hubs: false,
              add_users: false,
              hub_settings: false,
              application_permission: false,
              access: {
                mode: "all",
                selected: [],
              },
            });
            this.$refs.accessBlockRef?.clear_selected();
          })
          .catch(() => {
            this.info.type = "failed";
            this.info.error = true;
          })
          .finally(() => {
            this.info.loader = false;
            setTimeout(() => (this.info.type = ""), 3000);
          });
      }
    },
  },
};
</script>

<style scoped></style>
