import { render, staticRenderFns } from "./accountsChangeBlock.vue?vue&type=template&id=665b88ea&scoped=true&"
import script from "./accountsChangeBlock.vue?vue&type=script&lang=js&"
export * from "./accountsChangeBlock.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "665b88ea",
  null
  
)

export default component.exports